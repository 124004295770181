import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, Result } from 'antd';
import "./InfPanel.css";
import { RatingData } from '../../hooks/Rating/Rating';
import RiskClimatic from './RiskClimatic';
import AnalisisCartera from './AnalisisCartera';
import { requestPost } from '../../hooks/Request/Request';
import PanelActivo from './PanelActivo';
import ValoracionActivos from '../ComponenteValoracion/ValoracionActivos';
import IndicadoresClimaticos from '../IndicadoresClimaticos/IndicadoresClimaticos';
import Sello from '../../view/pages/SelloVeltis.png'
import Cee from './Cee.jsx';
import CeeBilbao from './CeeBilbao';
import Affecciones from './Affecciones';
import Protecciones from './Protecciones';
import Liquidez from '../Liquidez/Liquidez';
import Taxonomia from './Taxonomia';
import RFisicos from '../R_Fisicos/RFisicos.jsx';
import RAfeccion from '../R_Afeccion/RAfeccion.jsx';


const arrayCategorias = [
    { id: 0, name: "Rating Activo", disabled: false, },
    { id: 1, name: "Valoración", disabled: false, },
    //{ id: 9, name: "Liquidez", disabled: false, },
    { id: 2, name: "R. Sistémicos", disabled: false, },
    { id: 11, name: "R. Físicos", disabled: false, },
    { id: 12, name: "R. Afecciones", disabled: false, },
    //{ id: 7, name: "CEEbilbao", disabled: false, },
    { id: 8, name: "CEE", disabled: false, },
    { id: 10, name: "Taxonomía", disabled: false, },
    //{ id: 3, name: "R. Climáticos", disabled: false, },
    //{ id: 4, name: "Afecciones", disabled: false, },
    //{ id: 5, name: "Protecciones", disabled: false, },
    { id: 6, name: "Análisis Cartera", disabled: false, },
]

// const datos = [
//     {
//         id: 0,
//         nombre: 'Datos Demograficos',
//         datos: [
//             { id: 0, nombre: 'idCusec', dato: 1 },
//             { id: 1, nombre: 'rentaMediaHogar', dato: 40000 },
//             { id: 2, nombre: 'mediaIngresosSalario', dato: 10000 },
//             { id: 3, nombre: 'mediaIngresosPensiones', dato: 5000 },
//             { id: 4, nombre: 'mediaIngresosDesempleo', dato: 100 },
//             { id: 5, nombre: 'mediaOtrasPrestaciones', dato: 800 },
//             { id: 6, nombre: 'mediaOtrosIngresos', dato: 3000 }
//         ],
//     },
//     {
//         id: 1,
//         nombre: 'Valor',
//         datos: [
//             { id: 0, nombre: 'valorMedioEntorno', dato: 200000 },
//             { id: 1, nombre: 'precioNivelRentas', dato: 58742 }
//         ],
//     },
//     {
//         id: 2,
//         nombre: 'Activos',
//         datos: [
//             { id: 0, nombre: 'nViviendas', dato: 600 },
//             { id: 0, nombre: 'viviendasPrincip', dato: 800 },
//             { id: 0, nombre: 'viviendasSecund', dato: 50 }
//         ]
//     }
// ]


const Testt2 = ({ titulo, idd, activoSelected, carteras, setCarteras, dataa }) => {

    const [accesoPermitido, setaccesoPermitido] = useState(false);
    //const [dataa, setData] = useState(null);



    /*useEffect(() => {
        async function fetchData() {
            const result = await requestPost(
                'https://terminal.veltisrating.com:8888/api/activo',
                { refcat: activoSelected.full_refcat }
            )
            if( result.data[0].arr_precio === null ) result.data[0].arr_precio = []; 
            result.data[0].arr_preciototal = [];
            result.data[0].arr_precio.forEach((element, index) => {
                result.data[0].arr_preciototal.push(element * result.data[0].sup_elememtos_constru_asociados);
            });
            const result_panel = await requestPost(
                'https://terminal.veltisrating.com:8888/api/getActivoBarrioMunData', 
                { refcat: activoSelected.full_refcat }
            )
            Object.assign(result.data[0], result_panel.data[0]);
            setData(result);
        }
        if (activoSelected !== null && activoSelected !== undefined ) { 
            fetchData();
        }
    }, [activoSelected]);*/

    if (idd !== 6) {
        if (dataa === null /*&& idd !== 6*/) {
            if (activoSelected === null || activoSelected === undefined) {
                return (
                    <Result
                        //status="success"
                        icon={null}
                        //title="No se puede mostrar información relativa a un activo"
                        subTitle="Seleccione un activo inmobiliario antes de poder acceder a esta sección."
                        style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    /*extra={[
                        <Button type="primary" key="console">
                            Go Console
                        </Button>,
                        <Button key="buy">Buy Again</Button>,
                    ]}*/
                    />
                );
            } else {
                return (
                    <Result icon={<img src={Sello} alt='sello veltis' style={{ height: 'auto', width: '75%' }} />} title="Cargando información" style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                );
            }
        }
    }


    const moco = dataa !== null ? RatingData(dataa.data[0].rating_parcela) : '';
    const array_var = dataa !== null ? dataa.data[0].arr_var : '';

    let datosDemog = [];




    if (idd === 0) {
        datosDemog = [
            {
                id: 0,
                nombre: 'Datos Demográficos',
                datos: [
                    { id: 0, nombre: 'Renta media por hogar', dato: dataa.data[0].rmh_2019 },
                    { id: 1, nombre: 'Dist. Renta P80/P20 (percentil)', dato: dataa.data[0].p8020_2019 },
                    { id: 2, nombre: 'Renta media por persona', dato: dataa.data[0].rmp_2019 },
                    { id: 3, nombre: 'Media Ingresos salario', dato: dataa.data[0].fi_sal_2019 },
                    { id: 4, nombre: 'Media Ingresos pensiones', dato: dataa.data[0].fi_pen_2019 },
                    { id: 5, nombre: 'Media Ingresos prestaciones desempleo', dato: dataa.data[0].fi_des_2019 },
                    { id: 6, nombre: 'Media Ingresos otras prestaciones', dato: dataa.data[0].fi_op_2019 },
                    { id: 7, nombre: 'Media Ingresos otros ingresos', dato: dataa.data[0].fi_oi_2019 },
                ]
            },
            {
                id: 1,
                nombre: 'Valor',
                datos: [
                    { id: 0, nombre: 'Valor medio entorno', dato: dataa.data[0].precio_vir_cusec },
                    { id: 1, nombre: 'Precio por nivel rentas', dato: dataa.data[0].precio },
                ]
            },
            {
                id: 2,
                nombre: 'Activos',
                datos: [
                    { id: 0, nombre: 'Nº Viviendas', dato: dataa.data[0].t16_1 },
                    { id: 1, nombre: 'Viviendas Principales', dato: dataa.data[0].t17_1 },
                    { id: 2, nombre: 'Viviendas Secundarias', dato: dataa.data[0].t17_2 },
                    { id: 3, nombre: 'Viviendas Vacias', dato: dataa.data[0].t17_3 },
                    { id: 4, nombre: 'Viviendas menores a 30 m', dato: dataa.data[0].t19_1 },
                    { id: 5, nombre: 'Viviendas entre 30 y 45 m', dato: dataa.data[0].t19_2 },
                    { id: 6, nombre: 'Viviendas entre 46 y 60 m', dato: dataa.data[0].t19_3 },
                    { id: 7, nombre: 'Viviendas entre 61 y 75 m', dato: dataa.data[0].t19_4 },
                    { id: 8, nombre: 'Viviendas entre 76 y 90 m', dato: dataa.data[0].t19_5 },
                    { id: 9, nombre: 'Viviendas entre 91 y 105 m', dato: dataa.data[0].t19_6 },
                    { id: 10, nombre: 'Viviendas entre 106 y 120 m', dato: dataa.data[0].t19_7 },
                    { id: 11, nombre: 'Viviendas entre 121 y 150 m', dato: dataa.data[0].t19_8 },
                    { id: 12, nombre: 'Viviendas entre 151 y 180 m', dato: dataa.data[0].t19_9 },
                    { id: 13, nombre: 'Viviendas mayores a 180 m', dato: dataa.data[0].t19_10 },
                    { id: 14, nombre: 'Superficie media', dato: dataa.data[0].avg_superficie_cusec },
                ]
            },
            {
                id: 3,
                nombre: 'Población',
                datos: [
                    { id: 0, nombre: 'Edad media pob.', dato: dataa.data[0].emp_2019 },
                    { id: 1, nombre: 'Población < 18 años', dato: dataa.data[0].ppm18_2019 },
                    { id: 2, nombre: 'Población > 65 años', dato: dataa.data[0].ppm65_2019 },
                    { id: 3, nombre: 'Población total', dato: dataa.data[0].pob_2019 },
                    { id: 4, nombre: 'Tamaño medio hogar', dato: dataa.data[0].tmh_2019 },
                    { id: 5, nombre: 'Hogares Unipersonales', dato: dataa.data[0]['2019phuni_'] },
                    { id: 6, nombre: '% Viviendas 1 habitación', dato: dataa.data[0].t20_1 },
                    { id: 7, nombre: '% Viviendas 2 habitaciones', dato: dataa.data[0].t20_2 },
                    { id: 8, nombre: '% Viviendas 3 o más habitaciones', dato: dataa.data[0].t20_3 },
                ]
            },
            {
                id: 4,
                nombre: 'Alquiler',
                datos: [
                    { id: 0, nombre: 'Viviendas en alquiler', dato: dataa.data[0].t18_4 },
                    { id: 1, nombre: 'Precio medio', dato: dataa.data[0].ALQM2mes_LV_M_VC_18 * 100 },
                ]
            }
        ];
    }


    if (idd === 0) {
        return (
            <PanelActivo moco={moco} dataa={dataa} datos={datosDemog} array_var={array_var} />
        )
    }
    else if (idd === 1) {
        if (!accesoPermitido) {
            return (
                <ValoracionActivos dataa={dataa} />
            )
        } else {
            return (
                <h3>ACCESO CONFIRMADO!</h3>
            )
        }
    }
    else if (idd === 2) {
        return (
            <>
                <RiskClimatic dataa={dataa} />
            </>
        );
    }
    else if (idd === 3) {
        return <IndicadoresClimaticos dataa={dataa} />;
    } 
    else if (idd === 11) {
        return <RFisicos dataa={dataa} />;
    }
    else if (idd === 12) {
        return <RAfeccion dataa={dataa} />;
    }
    else if (idd === 4) {
        return <Affecciones />;
    }
    else if (idd === 5) {
        return <Protecciones />;
    }
    else if (idd === 6) {
        return <AnalisisCartera dataa={dataa} carteras={carteras} setCarteras={setCarteras} />
    }
    else if (idd === 7) {
        return <CeeBilbao dataa={dataa} />
    }
    else if (idd === 8) {
        return <Cee dataa={dataa} />
    }
    else if (idd === 10) {
        return <Taxonomia dataa={dataa} />
    }
    else if (idd === 9) {
        return <Liquidez dataa={dataa} />;
    }
    else {
        return "";
    }

}

const InfPanel = ({ activoSelected, carteras, setCarteras }) => {
    const divStyle = useMemo(() => ({ height: "calc(100% - 20px)", width: "calc(100% - 25px)", padding: 10, overflow: 'hidden' }), []);
    const [datos, setDatos] = useState(arrayCategorias);
    const [dataa, setData] = useState(null);
    const [activeKey, setActiveKey] = useState("0");

    useEffect(() => {
        async function fetchData() {
            const result = await requestPost(
                'https://terminal.veltisrating.com:8888/api/activo',
                { refcat: activoSelected.full_refcat }
            )
            if (result.data[0].arr_precio === null) result.data[0].arr_precio = [];
            result.data[0].arr_preciototal = [];
            result.data[0].arr_precio.forEach((element, index) => {
                result.data[0].arr_preciototal.push(element * result.data[0].sup_elememtos_constru_asociados);
            });

            const result_panel = await requestPost(
                'https://terminal.veltisrating.com:8888/api/getActivoBarrioMunData',
                { refcat: activoSelected.full_refcat }
            )
            Object.assign(result.data[0], result_panel.data[0]);
            setData(result);
        }
        if (activoSelected !== null && activoSelected !== undefined) {
            fetchData();
        }
    }, [activoSelected]);

    const onChange = (key) => {
        setActiveKey(key);
    };

    useEffect(() => {
        if (String(activeKey) === "6" && activoSelected !== null && activoSelected !== undefined) {
            setActiveKey("0");
        }
    }, [activoSelected])

    return (
        <div style={divStyle}>
            <div style={{ borderBottom: '1px solid black' }}><h3>Panel de Información {activoSelected !== null && activoSelected !== undefined ? <span style={{ textDecoration: 'underline 1px dotted' }}>({activoSelected.full_refcat})</span> : ""}</h3></div>
            <Tabs
                defaultActiveKey="0"
                destroyInactiveTabPane={false}
                tabPosition={'top'}
                activeKey={activeKey}
                onChange={onChange}
                style={{
                    //height: '20%',
                }}
                items={datos.map((item) => {
                    const id = String(item.id);
                    return {
                        label: item.name,
                        key: id,
                        disabled: item.disabled,
                        children: <Testt2 titulo="title" idd={item.id} activoSelected={activoSelected} carteras={carteras} setCarteras={setCarteras} dataa={dataa} />,
                    };
                })}
            />
        </div>
    )
}

export default InfPanel